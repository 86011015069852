import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BetaHomeView from '../views/beta/BetaHomeView.vue'
import ToolsView from '@/views/ToolsView.vue'

const routes = [
  {
    path: '/public',
    name: 'public',
    component: HomeView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/beta',
    name: 'beta',
    component: BetaHomeView
  },
  {
    path: '/tools',
    name: 'tools',
    component: ToolsView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
