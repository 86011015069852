<template>

  <el-menu default-active="1" class="el-menu-vertical-demo" router>
    <el-menu-item index="1" route="/public">
      <template #title>自定义模板报表</template>
      <!-- <span>自定义模板报表</span> -->
    </el-menu-item>
    <el-menu-item index="2" route="/beta">
      <template #title>看版数据导出</template>
      <!-- <span>看版数据导出</span> -->
    </el-menu-item>
    <el-menu-item index="3" route="/tools">
      <template #title>工具</template>
      <!-- <span>看版数据导出</span> -->
    </el-menu-item>
  </el-menu>
</template>
<script setup>
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 150px;
  min-height: 700px;
}
</style>