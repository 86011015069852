<template>
  <div>
    <ul class="tabs">
      <li v-for="(tab, index) in tabs" :key="index" :class="{ active: currentTab === index }" @click="currentTab = index">
        {{ tab }}
      </li>
    </ul>
    <div class="session-container">
      <input v-model="prefix" placeholder="请输入短链名称前缀" class="session-input" />
      <input v-model="session" :placeholder="isSessionEditable ? '请输入 session' : ''" :required="isSessionEditable" :disabled="!isSessionEditable" class="session-input" />
      <el-button v-if="isSessionEditable" type="primary" @click="saveSession">保存Session</el-button>
      <el-button v-else type="primary" @click="editSession">修改Session</el-button>
    </div>
    <div class="button-container">
      <!-- 添加: 添加短链行按钮 -->
      <el-button type="primary" @click="addShortLink">添加短链行</el-button>
      <!-- 添加: 下载导入模板按钮 -->
      <el-button type="primary" @click="downloadTemplate">下载导入模板</el-button>
      <!-- 添加: 将导入和导出按钮放到一起 -->
      <el-upload action="#" :auto-upload="false" :on-change="handleFileUpload" accept=".xlsx, .xls" :show-file-list="false">
        <el-button slot="trigger" type="primary">导入文件</el-button>
      </el-upload>
      <el-button type="primary" @click="batchGenerateShortLinks">批量生成短链</el-button>
      <el-button type="primary" @click="exportToExcel">导出Excel</el-button>
    </div>
    <div class="tab-content">
      <div v-if="currentTab === 0">
        <el-table :data="shortLinks" style="width: 100%">
          <el-table-column align="center" prop="taobaoUrl" label="淘宝链接" width="280">
            <template #default="scope">
              <el-input v-model="scope.row.taobaoUrl" placeholder="请输入淘宝链接" class="table-input" />
            </template>
          </el-table-column>
          <el-table-column align="center" prop="shortLinkName" label="短链名称" width="150">
            <template #default="scope">
              <!-- 修改: 根据状态决定是否可编辑 -->
              <el-input v-if="scope.row.status !== '已生成'" v-model="scope.row.shortLinkName" placeholder="请输入短链名称" class="table-input" />
              <span v-else>{{ scope.row.shortLinkName }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="短链生成状态" width="120" />
          <el-table-column align="center" prop="result" label="生成结果" width="180" />
          <el-table-column align="center" prop="expirationDate" label="链接有效期" width="200" /> <!-- 修改: 移动到生成结果后面 -->
          <el-table-column align="center" label="操作" width="300">
            <template #default="scope">
              <el-button type="primary" @click="generateShortLink(scope.$index)">生成短链</el-button>
              <el-button type="danger" @click="removeShortLink(scope.$index)">删除</el-button>
              <!-- 添加: 复制按钮 -->
              <el-button type="success" @click="copyShortLinkResult(scope.row.result)">复制结果</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/http.js'; // 引入 http.js
import { v4 as uuidv4 } from 'uuid'; // 引入 uuid 库
import { ElMessage } from 'element-plus'; // 引入 ElMessage 组件
import * as XLSX from 'xlsx'; // 使用命名导入的方式

export default {
  data() {
    return {
      tabs: ['淘宝短链生成'],
      currentTab: 0,
      session: '', // 新增 session 数据
      prefix: '', // 新增 prefix 数据
      shortLinks: [ // 新增 shortLinks 数组
        { taobaoUrl: '', shortLinkName: this.prefix ? `${this.prefix}_0` : '', expirationDate: '', status: '未生成', result: '' }, // 修改: 初始化短链名称和有效期
      ],
      isSessionEditable: true // 新增 isSessionEditable 数据
    };
  },
  watch: {
    prefix: function (newPrefix) { // 监听 prefix 变化
      this.shortLinks.forEach((item, index) => {
        if (item.status === '已生成') {
        } else {
          item.shortLinkName = newPrefix ? `${newPrefix}_${index}` : ''; // 修改: 如果 prefix 为空，则设置为空字符串
        }

      });
    }
  },
  mounted() {
    this.session = localStorage.getItem('session') || ''; // 直接从 localStorage 中读取 session
    this.isSessionEditable = !this.session; // 如果 session 存在，则设置为不可编辑
  },
  methods: {
    generateShortLink(index) {
      if (!this.session) {
        ElMessage.error('请先填写 session');
        return;
      }
      const session = this.session; // 使用用户输入的 session
      const shortLinkName = this.shortLinks[index].shortLinkName; // 直接使用短链名称
      const taobaoUrl = this.shortLinks[index].taobaoUrl; // 获取淘宝链接

      // 添加: 验证短链名称和链接是否为空
      if (!shortLinkName) {
        ElMessage.error('请填写短链名称');
        return;
      }
      if (!taobaoUrl) {
        ElMessage.error('请填写淘宝链接');
        return;
      }

      this.shortLinks[index].status = '生成中'; // 更新状态为生成中
      api.post('/tb/api/v1/generate-short-link', {
        short_link_name: shortLinkName,
        short_link_data: taobaoUrl,
        session: session
      }).then(response => {
        if (response.code === 200 && response.msg == 'Success') {
          this.shortLinks[index].status = '已生成'; // 更新状态为已生成
          this.shortLinks[index].result = response.data.short_link;
          this.shortLinks[index].expirationDate = this.calculateExpirationDate(); // 新增: 计算有效期
        } else {
          console.log('Error:', response.msg);
          this.shortLinks[index].status = '未生成'; // 更新状态为未生成
          // 显示 sub_code 提示
          if (response.data && response.data.sub_code) {
            ElMessage.error(`Error Code: ${response.data.sub_code}, Message: ${response.data.sub_msg}`);
          }
        }
      }).catch(error => {
        console.log(error);
        this.shortLinks[index].status = '未生成'; // 更新状态为未生成
        ElMessage.error('请求失败，请重试');
      });
    },
    batchGenerateShortLinks() { // 新增方法
      if (!this.session) {
        ElMessage.error('请先填写 session');
        return;
      }
      const session = this.session; // 使用用户输入的 session
      this.shortLinks.forEach(item => {
        if (item.status === '已生成') {
          console.log(`Short link with name ${item.shortLinkName} is already generated.`); // 日志记录
        } else {
          item.status = '生成中'; // 更新状态为生成中
        }
      });
      const promises = this.shortLinks.map((item, index) => {
        if (item.status === '已生成') {
          return Promise.resolve(); // 跳过生成
        }
        // 添加: 验证短链名称和链接是否为空
        if (!item.shortLinkName) {
          ElMessage.error(`第 ${index + 1} 行短链名称不能为空`);
          item.status = '生成失败'; // 更新状态为生成失败
          return Promise.resolve(); // 跳过生成
        }
        if (!item.taobaoUrl) {
          ElMessage.error(`第 ${index + 1} 行淘宝链接不能为空`);
          item.status = '生成失败'; // 更新状态为生成失败
          return Promise.resolve(); // 跳过生成
        }

        const shortLinkName = item.shortLinkName; // 直接使用短链名称
        return api.post('/tb/api/v1/generate-short-link', {
          short_link_name: shortLinkName,
          short_link_data: item.taobaoUrl,
          session: session
        }).then(response => {
          if (response.code === 200 && response.msg == 'Success') {
            this.shortLinks[index].status = '已生成'; // 更新状态为已生成
            this.shortLinks[index].result = response.data.short_link;
            this.shortLinks[index].expirationDate = this.calculateExpirationDate(); // 新增: 计算有效期
          } else {
            console.log('Error:', response.msg);
            this.shortLinks[index].status = '生成失败'; // 更新状态为生成失败
            // 显示 sub_code 提示
            if (response.data && response.data.sub_code) {
              ElMessage.error(`Error Code: ${response.data.sub_code}, Message: ${response.data.sub_msg}`);
            }
          }
        }).catch(error => {
          console.log(error);
          this.shortLinks[index].status = '生成失败'; // 更新状态为生成失败
          ElMessage.error('请求失败，请重试');
        });
      });
      Promise.all(promises).then(() => {
        console.log('Batch short links generated');
      });
    },
    // 添加: 添加短链行方法
    addShortLink() {
      const newIndex = this.shortLinks.length;
      this.shortLinks.push({ taobaoUrl: '', shortLinkName: this.prefix ? `${this.prefix}_${newIndex}` : '', expirationDate: '', status: '未生成', result: '' }); // 修改: 如果 prefix 为空，则设置为空字符串
    },
    // 添加: 删除短链行方法
    removeShortLink(index) {
      this.shortLinks.splice(index, 1);
    },
    // 添加: 复制短链结果方法
    copyShortLinkResult(result) {
      if (result) {
        navigator.clipboard.writeText(result)
          .then(() => {
            ElMessage.success('复制成功');
          })
          .catch(err => {
            console.error('复制失败', err);
            ElMessage.error('复制失败，请重试');
          });
      } else {
        ElMessage.warning('没有生成结果可复制');
      }
    },
    // 添加: 下载导入模板方法
    downloadTemplate() {
      // 确保 XLSX 库正确加载
      if (typeof XLSX === 'undefined' || typeof XLSX.utils === 'undefined') {
        console.error('XLSX 库未正确加载');
        ElMessage.error('XLSX 库未正确加载，请检查依赖');
        return;
      }
      const templateData = [
        { taobaoUrl: '', shortLinkName: '' }, // 修改: 增加 expirationDate 字段
      ];
      const worksheet = XLSX.utils.json_to_sheet(templateData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');
      XLSX.writeFile(workbook, 'short_links_template.xlsx');
    },
    // 添加: 处理文件上传方法
    handleFileUpload(event) {
      console.log('eventeventevent', event)
      // 确保 event 和 event.target.files 存在
      if (!event || !event.raw) {
        console.error('Invalid file upload event');
        ElMessage.error('文件上传事件无效，请重试');
        return;
      }
      const file = event.raw;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: ['taobaoUrl', 'shortLinkName', 'status', 'result', 'expirationDate'] }); // 修改: 增加 expirationDate 字段
          this.shortLinks = []; // 清空 shortLinks 数组
          this.shortLinks = json.slice(1).map((item, index) => ({ // 跳过表头行
            taobaoUrl: item.taobaoUrl,
            shortLinkName: item.shortLinkName || `${this.prefix}_${index}`,
            status: item.status || '未生成',
            result: item.result || '',
            expirationDate: item.expirationDate || '' // 修改: 处理 expirationDate 字段
          }));
        };
        reader.readAsArrayBuffer(file);
      }
    },
    // 添加: 导出Excel方法
    exportToExcel() {
      const worksheet = XLSX.utils.json_to_sheet(this.shortLinks);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'ShortLinks');
      XLSX.writeFile(workbook, 'short_links.xlsx');
    },
    // 新增: 计算有效期方法
    calculateExpirationDate() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + 6);
      return currentDate.toISOString().slice(0, 19).replace('T', ' ');
    },
    saveSession() {
      localStorage.setItem('session', this.session);
      this.isSessionEditable = false;
      ElMessage.success('Session 保存成功');
    },
    editSession() {
      this.isSessionEditable = true;
    }
  }
};
</script>

<style scoped>
.tabs {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.tabs li {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-bottom: none;
}

.tabs li.active {
  background-color: #f0f0f0;
  border-top: 2px solid #007bff;
}

.tab-content {
  border: 1px solid #ccc;
  padding: 20px;
  min-height: 200px;
}

.session-container {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.session-input {
  padding: 10px;
  font-size: 16px;
  width: 300px;
  margin-right: 10px;
  outline: none;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
  gap: 10px; /* 添加: 增加按钮间距 */
}

.button-container .el-button:first-child {
  background-color: #409eff; /* 添加: 第一个按钮颜色 */
}

.button-container .el-button:nth-child(2) {
  background-color: #67c23a; /* 添加: 第二个按钮颜色 */
}

.button-container .el-button:nth-child(3) {
  background-color: #e6a23c; /* 添加: 第三个按钮颜色 */
}

.button-container .el-button:nth-child(4) {
  background-color: #f56c6c; /* 添加: 第四个按钮颜色 */
}

.short-link-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.short-link-table th,
.short-link-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.short-link-table th {
  background-color: #f0f0f0;
}

.table-input {
  padding: 8px;
  font-size: 14px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  outline: none;
}
</style>